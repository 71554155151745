@import "@/components/common/colors.scss";

.checkbox {
  border: solid $blue 1px;
  height: 20px;
  width: 20px;
  border-radius: 20%;

  &:checked {
    background-color: $blue;
    border-color: $blue;
  }

  &::before {
    top: 4px;
    left: 7px;
  }
}
